<script>
import {loggedInfoObject} from "@/state/helpers";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";
import {msgModalOptCenter} from "@/components/globalFunctions";

export default {
  name: "ControlMsg",

  components: {
    flatPickr,
  },


  props: {
    mrtSms: {
      type: Object
    }
  },

  computed: {
    ...loggedInfoObject
  },

  data() {
    return {
      timeOptions: {
        enableTime: true,
        noCalendar: true,
        time_24hr: true,
        dateFormat: "H:i"
      },
      msgAble: false
    }
  },

  /*mounted() {
    console.log(this.mrtSms)
  },*/

  methods: {
    printParam() {
      console.log(this.mrtSms, this.logged_info)
    },

    changeMsgAbleStatus() {
      this.msgAble = this.mrtSms.mrtSmsBtn === 'Y'
    },

    putChangeMsgAbleStatus() {
      let url = `/api/market/info/mrtsmsbtn/${this.logged_info.mart_srl}`
      console.log(url)
      let param = {use: ''}

      if (this.msgAble) {
        param.use = "Y"
      } else {
        param.use = "N"
      }

      console.log(param)

      axios.put(url, param)
          .then((res) => {
            console.log(res.data)

            if (res.data.status === "success" && this.msgAble) {
              this.$bvModal.msgBoxOk(
                  "웹 문자메시지 보내기가 활성화 되었습니다.\n전화번호와 받을 시간을 입력 후 변경사항저장을 해주세요.",
                  msgModalOptCenter
              );
            } else {
              this.$bvModal.msgBoxOk(
                  "웹 문자메시지 보내기가 비활성화 되었습니다.",
                  msgModalOptCenter
              );
            }
          })
          .catch(
              err => console.log('putChangeMsgAbleStatus Error : ', err)
          )
    },

    settingMsgCondition() {
      let url = `/api/market/info/mrtsmsinfo/${this.logged_info.mart_srl}`

      let param = {
        phone: this.mrtSms.mrtSmsPhone,
        stime: this.mrtSms.mrtSmsStartTime,
        etime: this.mrtSms.mrtSmsEndTime
      }

      let sTime = new Date(`1970-01-01T${param.stime}`).getTime()
      let eTime = new Date(`1970-01-01T${param.etime}`).getTime()

      console.log(sTime)
      console.log(eTime)
      console.log(sTime > eTime, sTime === eTime, param.stime === param.etime)

      if (sTime > eTime) {
        this.$bvModal.msgBoxOk(
            "시간을 확인 해주세요.",
            msgModalOptCenter
        );
        return
      } else if (sTime === eTime) {
        let sChk = false
        sChk = param.stime === '00:00'

        let eChk = false
        eChk = param.etime === '00:00'

        if (!sChk && !eChk) {
          this.$bvModal.msgBoxOk(
              "시간을 확인 해주세요.",
              msgModalOptCenter
          );
          return
        }
      }

      axios.put(url, param)
          .then((res) => {
            console.log(res.data)

            if (res.data.status === "success" && this.msgAble) {
              this.$bvModal.msgBoxOk(
                  "웹 문자메시지 정보가 수정되었습니다.",
                  msgModalOptCenter
              );
            } else {
              this.$bvModal.msgBoxOk(
                  `웹 문자메시지 정보 갱신에 실패하였습니다.`,
                  msgModalOptCenter
              );
            }
          })
          .catch(
              err => console.log("settingMsgCondition Error :", err)
          )
    }
  }
}
</script>

<template>
  <!-- 웹에서 주문 결제 설정 -->
  <div class="card" v-if="logged_info.business_type !== 'clan'">
    <div class="card-header">
      <h5 style="margin: 0" @click="printParam">웹(광고링크)에서 문자메시지</h5>
    </div>
    <div class="card-body">
      <form @submit.prevent>
        <div class="form-check form-switch form-switch-md" style="margin-bottom: 16px;">
          <input
              v-model="msgAble"
              class="form-check-input"
              type="checkbox"
              id="msgAble"
              @change="putChangeMsgAbleStatus"
          />
          <label
              class="form-check-label"
              for="msgAble"
              style="padding-right: 50px">
            메시지 가능
          </label>
        </div>
        <div v-show="msgAble" class="mb-2">
          <div class="row">
            <div class="mb-3">
              <label class="form-label" for="ceo_phone">
                웹(광고링크)에서 메시지 받을 전화번호
              </label>
              <input
                  type="text"
                  class="form-control"
                  id="ceo_phone"
                  placeholder="메시지 받을 전화번호"
                  onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                  v-model="mrtSms.mrtSmsPhone"
              />
            </div>

            <div class="mb-3" style="display: flex; width: 100%">
              <div style="width: 100%; padding-right: 10px;">
                <label class="form-label col-lg-6">
                  메시지가능 시작시간
                </label>
                <flat-pickr
                    v-model="mrtSms.mrtSmsStartTime"
                    :config="timeOptions"
                    class="form-control"
                >
                </flat-pickr>
              </div>
              <div style="width: 100%; padding-left: 10px;">
                <label class="form-label col-lg-6">
                  메시지가능 종료시간
                </label>
                <flat-pickr
                    v-model="mrtSms.mrtSmsEndTime"
                    :config="timeOptions"
                    class="form-control"
                >
                </flat-pickr>
              </div>
            </div>
          </div>

          <div class="text-end">
            <button class="btn btn-primary w-sm" @click="settingMsgCondition">
              변경사항저장
            </button>
          </div>
        </div>
      </form>
      <!-- end form -->
    </div>
    <!-- end card body -->
  </div>
  <!-- 웹에서 주문 결제 설정 End -->
</template>

<style scoped>

</style>
