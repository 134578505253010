<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";
import appConfig from "@/app.config.json";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {Korean} from "flatpickr/dist/l10n/ko.js";

import {mapState} from "vuex";
import {loggedInfoMethods, loggedInfoObject} from "@/state/helpers";
import axios from "axios";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";

import ControlMsg from "@/views/pages/account/myInfo/Modules/ControlMsg.vue";

/**
 * Gallery component
 */
export default {
  page: {
    title: "MY정보",
    meta: [{name: "description", content: appConfig.description}]
  },

  components: {
    Layout,
    PageHeader,
    flatPickr,
    ControlMsg
  },

  data() {
    return {
      title: "MY정보",
      items: "마켓의 회원정보를 관리합니다.",
      btnName: "",
      ceoPhone: "",
      marketTel: "",
      marketStime: "",
      marketEtime: "",
      marketInfoSet: {
        market_info: {
          mrt_sms_btn: '',
          mrt_sms_phone: '',
          mrt_sms_stime: '',
          mrt_sms_etime: ''
        }
      },
      timeOptions: {
        enableTime: true,
        noCalendar: true,
        time_24hr: true,
        dateFormat: "H:i"
      },
      config: {
        enableTime: false,
        minDate: "today",
        dateFormat: "Y-m-d",
        locale: Korean
      },
      dateOption: {
        enableTime: false,
        noCalendar: false,
        time_24hr: true,
        dateFormat: "yyyy-mm-dd"
      },
      oldPw: "",
      newPw: "",
      newPwChk: "",
      d_weekdaysSame: false,
      delivery: true,
      packing: true,
      m_everyday_stime: "",
      m_everyday_etime: "",
      m_mon_stime: "",
      m_mon_etime: "",
      m_tue_stime: "",
      m_tue_etime: "",
      m_wed_stime: "",
      m_wed_etime: "",
      m_thu_stime: "",
      m_thu_etime: "",
      m_fri_stime: "",
      m_fri_etime: "",
      m_sat_stime: "",
      m_sat_etime: "",
      m_sun_stime: "",
      m_sun_etime: "",
      allday: false,
      m_weekdaysSame: false,
      d_everyday_stime: "",
      d_everyday_etime: "",
      d_mon_stime: "",
      d_mon_etime: "",
      d_tue_stime: "",
      d_tue_etime: "",
      d_wed_stime: "",
      d_wed_etime: "",
      d_thu_stime: "",
      d_thu_etime: "",
      d_fri_stime: "",
      d_fri_etime: "",
      d_sat_stime: "",
      d_sat_etime: "",
      d_sun_stime: "",
      d_sun_etime: "",
      confirmationResult: false,
      dMinPrice: "",
      pMinPrice: "",
      offdays: [],
      yoil: [
        {name: "월요일"},
        {name: "화요일"},
        {name: "수요일"},
        {name: "목요일"},
        {name: "금요일"},
        {name: "토요일"},
        {name: "일요일"}
      ],
      week: [
        {name: "매주"},
        {name: "첫째주"},
        {name: "둘째주"},
        {name: "셋째주"},
        {name: "넷째주"}
      ],
      selectedYoil: "월요일",
      selectedWeek: "매주",
      legalholiday: false,
      meetNcash: false,
      meetNcard: false,
      tmpholiday: false,
      today: "",
      tmpholidaySdate: "",
      tmpholidayEdate: "",
      tmpholidayStime: "00:00",
      tmpholidayEtime: "23:59",
      tmpClosingDaySrl: "",
      webOrderCheck: false,
    };
  },

  computed: {
    ...mapState(loggedInfoObject)
  },

  mounted() {
    this.getMarketInfo();
    this.setNowTimes();
    this.ceoPhone = this.logged_info.phone;
    this.marketTel = this.logged_info.tel;
    this.marketStime = this.logged_info.stime;
    this.marketEtime = this.logged_info.etime;
  },

  methods: {
    ...loggedInfoMethods,

    //오늘 날짜 설정
    setNowTimes() {
      this.today = new Date().toJSON().slice(0, 10);
      this.tmpholidaySdate = this.today;
      this.tmpholidayEdate = this.today;
    },

    //마켓영업시간과 배달포장시간 가져오기
    getMarketInfo() {
      let url = `/api/marketinfo/${this.logged_info.mart_srl}`

      axios.get(url)
          .then(async response => {
            this.marketInfoSet = response.data;
            console.log(this.marketInfoSet);
            await this.setBizHourInfo();

            this.webOrderCheck = this.marketInfoSet.market_info.mrt_buy_btn === "Y";

            await this.$refs.ctrMsg.changeMsgAbleStatus()
          })
          .catch(error => {
            console.log(error);
          });
    },

    makeObject() {
      let tmp = {}
      // console.log(this.marketInfoSet.market_info.mrt_sms_btn)
      if (typeof this.marketInfoSet.market_info.mrt_sms_btn !== 'undefined') {
        tmp["mrtSmsBtn"] = this.marketInfoSet.market_info.mrt_sms_btn
      }

      if (typeof this.marketInfoSet.market_info.mrt_sms_etime !== 'undefined') {
        tmp["mrtSmsEndTime"] = this.marketInfoSet.market_info.mrt_sms_etime
      }

      if (typeof this.marketInfoSet.market_info.mrt_sms_stime !== 'undefined') {
        tmp["mrtSmsStartTime"] = this.marketInfoSet.market_info.mrt_sms_stime
      }

      if (typeof this.marketInfoSet.market_info.mrt_sms_phone !== 'undefined') {
        tmp["mrtSmsPhone"] = this.marketInfoSet.market_info.mrt_sms_phone
      }

      return tmp
    },

    //불러온 마트세부정보 세팅
    setBizHourInfo() {
      //console.log(this.marketInfoSet)
      this.m_mon_stime = this.marketInfoSet.market_info.market_open_close.mon_stime;
      this.m_mon_etime = this.marketInfoSet.market_info.market_open_close.mon_etime;
      this.m_tue_stime = this.marketInfoSet.market_info.market_open_close.tue_stime;
      this.m_tue_etime = this.marketInfoSet.market_info.market_open_close.tue_etime;
      this.m_wed_stime = this.marketInfoSet.market_info.market_open_close.wed_stime;
      this.m_wed_etime = this.marketInfoSet.market_info.market_open_close.wed_etime;
      this.m_thu_stime = this.marketInfoSet.market_info.market_open_close.thu_stime;
      this.m_thu_etime = this.marketInfoSet.market_info.market_open_close.thu_etime;
      this.m_fri_stime = this.marketInfoSet.market_info.market_open_close.fri_stime;
      this.m_fri_etime = this.marketInfoSet.market_info.market_open_close.fri_etime;
      this.m_sat_stime = this.marketInfoSet.market_info.market_open_close.sat_stime;
      this.m_sat_etime = this.marketInfoSet.market_info.market_open_close.sat_etime;
      this.m_sun_stime = this.marketInfoSet.market_info.market_open_close.sun_stime;
      this.m_sun_etime = this.marketInfoSet.market_info.market_open_close.sun_etime;

      if (
          this.m_mon_stime === this.m_tue_stime &&
          this.m_mon_stime === this.m_wed_stime &&
          this.m_mon_stime === this.m_thu_stime &&
          this.m_mon_stime === this.m_fri_stime &&
          this.m_mon_stime === this.m_sat_stime &&
          this.m_mon_stime === this.m_sun_stime &&
          this.m_mon_etime === this.m_tue_etime &&
          this.m_mon_etime === this.m_wed_etime &&
          this.m_mon_etime === this.m_thu_etime &&
          this.m_mon_etime === this.m_fri_etime &&
          this.m_mon_etime === this.m_sat_etime &&
          this.m_mon_etime === this.m_sun_etime
      ) {
        this.m_weekdaysSame = true;
        this.m_everyday_stime = this.m_mon_stime;
        this.m_everyday_etime = this.m_mon_etime;
      } else {
        this.m_weekdaysSame = false;
      }

      if (this.marketInfoSet.market_info.allday === "Y") {
        this.allday = true;
      } else {
        this.allday = false;
      }
      this.d_mon_stime = this.marketInfoSet.market_info.delivery_open_close.mon_stime;
      this.d_mon_etime = this.marketInfoSet.market_info.delivery_open_close.mon_etime;
      this.d_tue_stime = this.marketInfoSet.market_info.delivery_open_close.tue_stime;
      this.d_tue_etime = this.marketInfoSet.market_info.delivery_open_close.tue_etime;
      this.d_wed_stime = this.marketInfoSet.market_info.delivery_open_close.wed_stime;
      this.d_wed_etime = this.marketInfoSet.market_info.delivery_open_close.wed_etime;
      this.d_thu_stime = this.marketInfoSet.market_info.delivery_open_close.thu_stime;
      this.d_thu_etime = this.marketInfoSet.market_info.delivery_open_close.thu_etime;
      this.d_fri_stime = this.marketInfoSet.market_info.delivery_open_close.fri_stime;
      this.d_fri_etime = this.marketInfoSet.market_info.delivery_open_close.fri_etime;
      this.d_sat_stime = this.marketInfoSet.market_info.delivery_open_close.sat_stime;
      this.d_sat_etime = this.marketInfoSet.market_info.delivery_open_close.sat_etime;
      this.d_sun_stime = this.marketInfoSet.market_info.delivery_open_close.sun_stime;
      this.d_sun_etime = this.marketInfoSet.market_info.delivery_open_close.sun_etime;

      if (
          this.d_mon_stime == this.d_tue_stime &&
          this.d_mon_stime == this.d_wed_stime &&
          this.d_mon_stime == this.d_thu_stime &&
          this.d_mon_stime == this.d_fri_stime &&
          this.d_mon_stime == this.d_sat_stime &&
          this.d_mon_stime == this.d_sun_stime &&
          this.d_mon_etime == this.d_tue_etime &&
          this.d_mon_etime == this.d_wed_etime &&
          this.d_mon_etime == this.d_thu_etime &&
          this.d_mon_etime == this.d_fri_etime &&
          this.d_mon_etime == this.d_sat_etime &&
          this.d_mon_etime == this.d_sun_etime
      ) {
        //console.log("$$$$$$$$$")
        this.d_weekdaysSame = true;
        this.d_everyday_stime = this.d_mon_stime;
        this.d_everyday_etime = this.d_mon_etime;
      } else {
        console.log("#########");
        this.d_weekdaysSame = false;
      }

      this.pMinPrice = this.marketInfoSet.market_info.packing_price;
      this.dMinPrice = this.marketInfoSet.market_info.delivery_price;

      if (this.marketInfoSet.market_info.delivery_on.toLowerCase() === "y") {
        this.delivery = true;
      } else {
        this.delivery = false;
      }
      if (this.marketInfoSet.market_info.packing_on.toLowerCase() === "y") {
        this.packing = true;
      } else {
        this.packing = false;
      }
      //console.log("this.d_weekdaysSame : " + this.d_weekdaysSame + " , this.d_sun_etime : " + this.d_sun_etime)
      this.offdays = this.marketInfoSet.market_info.closingday;
      //console.log("this.offdays.length : " + this.offdays.length)

      if (this.marketInfoSet.market_info.holiday === "Y") {
        this.legalholiday = true;
      } else {
        this.legalholiday = false;
      }

      if (this.marketInfoSet.market_info.seecash === "Y") {
        this.meetNcash = true;
      } else {
        this.meetNcash = false;
      }

      if (this.marketInfoSet.market_info.seecard === "Y") {
        this.meetNcard = true;
      } else {
        this.meetNcard = false;
      }

      if (
          this.marketInfoSet.market_info.tmpclosingday.tmp_closing_day_srl != null
      ) {
        this.tmpholiday = true;
        this.tmpholidaySdate = this.marketInfoSet.market_info.tmpclosingday.sday;
        this.tmpholidayEdate = this.marketInfoSet.market_info.tmpclosingday.eday;
        this.tmpholidayStime = this.marketInfoSet.market_info.tmpclosingday.stime;
        this.tmpholidayEtime = this.marketInfoSet.market_info.tmpclosingday.etime;
        this.tmpClosingDaySrl = this.marketInfoSet.market_info.tmpclosingday.tmp_closing_day_srl;
      }
    },

    //비밀번호변경
    modifyPassword() {
      if (this.newPw.length < 8 || this.newPw.length > 14) {
        this.$bvModal.msgBoxOk(
            "비밀번호는 8자 이상 13자 이하로 입력해주세요.",
            msgModalOptCenter
        );
        return;
      }
      if (this.newPw != this.newPwChk) {
        this.$bvModal.msgBoxOk(
            "비밀번호를 정확히 입력해주세요.",
            msgModalOptCenter
        );
        return;
      }
      if (this.oldPw === this.newPw) {
        this.$bvModal.msgBoxOk(
            "기존 비밀번호와 다른 비밀번호를 입력해주세요.",
            msgModalOptCenter
        );
        return;
      }
      const accountInfo = {
        market_srl: this.logged_info.mart_srl,
        pw_be: this.oldPw,
        pw_af: this.newPw
      };
      // eslint-disable-next-line
      const key = this.$CryptoJS.enc.Utf8.parse(process.env.VUE_APP_KEY);
      // eslint-disable-next-line
      const iv = this.$CryptoJS.enc.Hex.parse(process.env.VUE_APP_IV);

      let encrypt = this.$CryptoJS.AES.encrypt(
          JSON.stringify(accountInfo),
          key,
          {iv: iv}
      );
      let strEncrypt = encrypt.toString().replaceAll("/", "*");

      let url = `/api/market/mydata/${strEncrypt}`

      axios.put(url, {
        encrypt: strEncrypt
      })
          .then(result => {
            console.log(result.data);

            if (
                result.data.status === "fail" &&
                result.data.msg === "pw is invaild"
            ) {
              this.$bvModal.msgBoxOk(
                  "기존 비밀번호가 잘못입력되었습니다. 다시 시도해주세요.",
                  msgModalOptCenter
              );
            } else if (result.data.status === "success") {
              this.$bvModal.msgBoxOk(
                  "비밀번호가 변경되었습니다.",
                  msgModalOptCenter
              );
              this.oldPw = "";
              this.newPw = "";
              this.newPwChk = "";
            }
          })
          .catch(error => {
            console.log(`Modifying Password ${error}`);
          });
    },

    //추가정보수정
    modifyPhoneInfo() {
      console.log(this.logged_info);
      if (
          this.ceoPhone === this.logged_info.phone &&
          this.marketTel === this.logged_info.tel
      ) {
        this.$bvModal.msgBoxOk("수정된 내용이 없습니다.", msgModalOptCenter);
        return;
      }

      if (this.ceoPhone.length < 11) {
        this.$bvModal.msgBoxOk(
            "대표자의 휴대전화번호를 확인해주세요.",
            msgModalOptCenter
        );
        return;
      }
      if (this.marketTel.length < 10) {
        this.$bvModal.msgBoxOk(
            "마켓의 대표번호를 확인해주세요.",
            msgModalOptCenter
        );
        return;
      }

      this.$bvModal
          .msgBoxConfirm(
              `대표자의 휴대전화 : ` +
              this.ceoPhone +
              "\n마켓의 대표번호 : " +
              this.marketTel +
              "\n 위와 같이 수정하시겠습니까?",
              msgConfirmOptCenter
          )
          .then(sel => {
            if (sel) {
              const accountInfo = {
                mart_srl: this.logged_info.mart_srl,
                phone: this.ceoPhone,
                tel: this.marketTel
              };
              // eslint-disable-next-line
              const key = this.$CryptoJS.enc.Utf8.parse(process.env.VUE_APP_KEY);
              // eslint-disable-next-line
              const iv = this.$CryptoJS.enc.Hex.parse(process.env.VUE_APP_IV);

              let encrypt = this.$CryptoJS.AES.encrypt(
                  JSON.stringify(accountInfo),
                  key,
                  {iv: iv}
              );
              let strEncrypt = encrypt.toString().replaceAll("/", "*");

              let url = `/api/user/mart/${strEncrypt}`

              axios.put(url, {
                encrypt: strEncrypt
              })
                  .then(result => {
                    console.log(result.data);
                    if (result.data.status === "success") {
                      var tmpLoggedInfo = this.logged_info;
                      tmpLoggedInfo.phone = this.ceoPhone;
                      tmpLoggedInfo.tel = this.marketTel;
                      this.setLoggedInfo(tmpLoggedInfo);
                    }
                  })
                  .catch(error => {
                    console.log(`modify Additional Infomation ${error}`);
                  });
            }
          });
    },

    //영업시간수정
    modifyMarketOC() {
      var tmpAllday = "";
      if (this.allday) {
        tmpAllday = "Y";
      } else {
        tmpAllday = "N";
      }

      if (this.m_weekdaysSame) {
        this.m_mon_stime = this.m_everyday_stime;
        this.m_mon_etime = this.m_everyday_etime;
        this.m_tue_stime = this.m_everyday_stime;
        this.m_tue_etime = this.m_everyday_etime;
        this.m_wed_stime = this.m_everyday_stime;
        this.m_wed_etime = this.m_everyday_etime;
        this.m_thu_stime = this.m_everyday_stime;
        this.m_thu_etime = this.m_everyday_etime;
        this.m_fri_stime = this.m_everyday_stime;
        this.m_fri_etime = this.m_everyday_etime;
        this.m_sat_stime = this.m_everyday_stime;
        this.m_sat_etime = this.m_everyday_etime;
        this.m_sun_stime = this.m_everyday_stime;
        this.m_sun_etime = this.m_everyday_etime;
      }

      if (
          tmpAllday != this.marketInfoSet.market_info.allday ||
          this.m_mon_stime !=
          this.marketInfoSet.market_info.market_open_close.mon_stime ||
          this.m_mon_etime !=
          this.marketInfoSet.market_info.market_open_close.mon_etime ||
          this.m_tue_stime !=
          this.marketInfoSet.market_info.market_open_close.tue_stime ||
          this.m_tue_etime !=
          this.marketInfoSet.market_info.market_open_close.tue_etime ||
          this.m_wed_stime !=
          this.marketInfoSet.market_info.market_open_close.wed_stime ||
          this.m_wed_etime !=
          this.marketInfoSet.market_info.market_open_close.wed_etime ||
          this.m_thu_stime !=
          this.marketInfoSet.market_info.market_open_close.thu_stime ||
          this.m_thu_etime !=
          this.marketInfoSet.market_info.market_open_close.thu_etime ||
          this.m_fri_stime !=
          this.marketInfoSet.market_info.market_open_close.fri_stime ||
          this.m_fri_etime !=
          this.marketInfoSet.market_info.market_open_close.fri_etime ||
          this.m_sat_stime !=
          this.marketInfoSet.market_info.market_open_close.sat_stime ||
          this.m_sat_etime !=
          this.marketInfoSet.market_info.market_open_close.sat_etime ||
          this.m_sun_stime !=
          this.marketInfoSet.market_info.market_open_close.sun_stime ||
          this.m_sun_etime !=
          this.marketInfoSet.market_info.market_open_close.sun_etime
      ) {
        this.$bvModal
            .msgBoxConfirm(
                "영업시간정보를 변경하시겠습니까?",
                msgConfirmOptCenter
            )
            .then(sel => {
              if (sel) {
                const fd = new FormData();
                if (this.m_weekdaysSame) {
                  fd.append(
                      "oc_srl",
                      this.marketInfoSet.market_info.market_open_close.oc_srl
                  );
                  fd.append("mon_stime", this.m_everyday_stime);
                  fd.append("mon_etime", this.m_everyday_etime);
                  fd.append("tue_stime", this.m_everyday_stime);
                  fd.append("tue_etime", this.m_everyday_etime);
                  fd.append("wed_stime", this.m_everyday_stime);
                  fd.append("wed_etime", this.m_everyday_etime);
                  fd.append("thu_stime", this.m_everyday_stime);
                  fd.append("thu_etime", this.m_everyday_etime);
                  fd.append("fri_stime", this.m_everyday_stime);
                  fd.append("fri_etime", this.m_everyday_etime);
                  fd.append("sat_stime", this.m_everyday_stime);
                  fd.append("sat_etime", this.m_everyday_etime);
                  fd.append("sun_stime", this.m_everyday_stime);
                  fd.append("sun_etime", this.m_everyday_etime);
                } else {
                  fd.append(
                      "oc_srl",
                      this.marketInfoSet.market_info.market_open_close.oc_srl
                  );
                  fd.append("mon_stime", this.m_mon_stime);
                  fd.append("mon_etime", this.m_mon_etime);
                  fd.append("tue_stime", this.m_tue_stime);
                  fd.append("tue_etime", this.m_tue_etime);
                  fd.append("wed_stime", this.m_wed_stime);
                  fd.append("wed_etime", this.m_wed_etime);
                  fd.append("thu_stime", this.m_thu_stime);
                  fd.append("thu_etime", this.m_thu_etime);
                  fd.append("fri_stime", this.m_fri_stime);
                  fd.append("fri_etime", this.m_fri_etime);
                  fd.append("sat_stime", this.m_sat_stime);
                  fd.append("sat_etime", this.m_sat_etime);
                  fd.append("sun_stime", this.m_sun_stime);
                  fd.append("sun_etime", this.m_sun_etime);
                }
                const fdata = new FormData();
                fdata.append("allday", tmpAllday);
                axios
                    .put(
                        `/api/market/info/allday/${this.logged_info.mart_srl}`,
                        fdata
                    )
                    .then(result => {
                      console.log(result.data);
                      if (result.data.status === "success") {
                        axios
                            .put(
                                `/api/market/info/intime/${this.logged_info.mart_srl}`,
                                fd
                            )
                            .then(result => {
                              console.log(result.data);
                              if (result.data.status === "success") {
                                this.getMarketInfo();
                              }
                            })
                            .catch(error => {
                              console.log(`modify Bizhour Infomation ${error}`);
                            });
                      }
                      this.$bvModal.msgBoxOk(
                          "영업시간정보가 수정되었습니다.",
                          msgModalOptCenter
                      );
                    })
                    .catch(error => {
                      console.log(`modify Bizhour Allday Infomation ${error}`);
                    });
              }
            });
      }
    },

    //배송(포장)가능시간수정
    serviceTimeModify() {
      var tmpDelivery = "";
      var tmpPacking = "";
      if (this.delivery) {
        tmpDelivery = "Y";
      } else {
        tmpDelivery = "N";
      }
      if (this.packing) {
        tmpPacking = "Y";
      } else {
        tmpPacking = "N";
      }
      if (this.d_weekdaysSame) {
        this.d_mon_stime = this.d_everyday_stime;
        this.d_mon_etime = this.d_everyday_etime;
        this.d_tue_stime = this.d_everyday_stime;
        this.d_tue_etime = this.d_everyday_etime;
        this.d_wed_stime = this.d_everyday_stime;
        this.d_wed_etime = this.d_everyday_etime;
        this.d_thu_stime = this.d_everyday_stime;
        this.d_thu_etime = this.d_everyday_etime;
        this.d_fri_stime = this.d_everyday_stime;
        this.d_fri_etime = this.d_everyday_etime;
        this.d_sat_stime = this.d_everyday_stime;
        this.d_sat_etime = this.d_everyday_etime;
        this.d_sun_stime = this.d_everyday_stime;
        this.d_sun_etime = this.d_everyday_etime;
      }

      if (
          tmpDelivery != this.marketInfoSet.market_info.delivery_on ||
          tmpPacking != this.marketInfoSet.market_info.packing_on ||
          this.d_mon_stime !=
          this.marketInfoSet.market_info.delivery_open_close.mon_stime ||
          this.d_mon_etime !=
          this.marketInfoSet.market_info.delivery_open_close.mon_etime ||
          this.d_tue_stime !=
          this.marketInfoSet.market_info.delivery_open_close.tue_stime ||
          this.d_tue_etime !=
          this.marketInfoSet.market_info.delivery_open_close.tue_etime ||
          this.d_wed_stime !=
          this.marketInfoSet.market_info.delivery_open_close.wed_stime ||
          this.d_wed_etime !=
          this.marketInfoSet.market_info.delivery_open_close.wed_etime ||
          this.d_thu_stime !=
          this.marketInfoSet.market_info.delivery_open_close.thu_stime ||
          this.d_thu_etime !=
          this.marketInfoSet.market_info.delivery_open_close.thu_etime ||
          this.d_fri_stime !=
          this.marketInfoSet.market_info.delivery_open_close.fri_stime ||
          this.d_fri_etime !=
          this.marketInfoSet.market_info.delivery_open_close.fri_etime ||
          this.d_sat_stime !=
          this.marketInfoSet.market_info.delivery_open_close.sat_stime ||
          this.d_sat_etime !=
          this.marketInfoSet.market_info.delivery_open_close.sat_etime ||
          this.d_sun_stime !=
          this.marketInfoSet.market_info.delivery_open_close.sun_stime ||
          this.d_sun_etime !=
          this.marketInfoSet.market_info.delivery_open_close.sun_etime ||
          this.dMinPrice != this.marketInfoSet.market_info.delivery_price ||
          this.pMinPrice != this.marketInfoSet.market_info.packing_price
      ) {
        this.$bvModal
            .msgBoxConfirm(
                "배달(포장)가능시간정보를 변경하시겠습니까?",
                msgConfirmOptCenter
            )
            .then(sel => {
              if (sel) {
                const fd = new FormData();

                fd.append("delivery", tmpDelivery);
                axios
                    .put(
                        `/api/market/info/delivery/${this.logged_info.mart_srl}`,
                        fd
                    )
                    .then(result => {
                      console.log(result.data.status + "  - delivery");
                      if (result.data.status === "success") {
                        const fdd = new FormData();
                        fdd.append("packing", tmpPacking);

                        axios
                            .put(
                                `/api/market/info/packing/${this.logged_info.mart_srl}`,
                                fdd
                            )
                            .then(result => {
                              console.log(result.data.status + "  - packing");
                              if (result.data.status === "success") {
                                const fddd = new FormData();
                                fddd.append(
                                    "delivery_oc_srl",
                                    this.marketInfoSet.market_info.delivery_open_close
                                        .delivery_oc_srl
                                );
                                if (this.d_weekdaysSame) {
                                  fddd.append("mon_stime", this.d_everyday_stime);
                                  fddd.append("mon_etime", this.d_everyday_etime);
                                  fddd.append("tue_stime", this.d_everyday_stime);
                                  fddd.append("tue_etime", this.d_everyday_etime);
                                  fddd.append("wed_stime", this.d_everyday_stime);
                                  fddd.append("wed_etime", this.d_everyday_etime);
                                  fddd.append("thu_stime", this.d_everyday_stime);
                                  fddd.append("thu_etime", this.d_everyday_etime);
                                  fddd.append("fri_stime", this.d_everyday_stime);
                                  fddd.append("fri_etime", this.d_everyday_etime);
                                  fddd.append("sat_stime", this.d_everyday_stime);
                                  fddd.append("sat_etime", this.d_everyday_etime);
                                  fddd.append("sun_stime", this.d_everyday_stime);
                                  fddd.append("sun_etime", this.d_everyday_etime);
                                } else {
                                  fddd.append("mon_stime", this.d_mon_stime);
                                  fddd.append("mon_etime", this.d_mon_etime);
                                  fddd.append("tue_stime", this.d_tue_stime);
                                  fddd.append("tue_etime", this.d_tue_etime);
                                  fddd.append("wed_stime", this.d_wed_stime);
                                  fddd.append("wed_etime", this.d_wed_etime);
                                  fddd.append("thu_stime", this.d_thu_stime);
                                  fddd.append("thu_etime", this.d_thu_etime);
                                  fddd.append("fri_stime", this.d_fri_stime);
                                  fddd.append("fri_etime", this.d_fri_etime);
                                  fddd.append("sat_stime", this.d_sat_stime);
                                  fddd.append("sat_etime", this.d_sat_etime);
                                  fddd.append("sun_stime", this.d_sun_stime);
                                  fddd.append("sun_etime", this.d_sun_etime);
                                }

                                axios.put(
                                    `/api/market/delivery/time/${this.logged_info.mart_srl}`,
                                    fddd
                                )
                                    .then(result => {
                                      console.log(
                                          result.data.status + "  - serviceTime"
                                      );
                                      if (result.data.status === "success") {
                                        const fdddd = new FormData();
                                        fdddd.append("price", this.dMinPrice);

                                        axios.put(
                                            `/api/market/info/delivery/price/${this.logged_info.mart_srl}`,
                                            fdddd
                                        )
                                            .then(result => {
                                              console.log(
                                                  result.data.status +
                                                  "  - delivery min price"
                                              );
                                              if (result.data.status === "success") {
                                                const fddddd = new FormData();
                                                fddddd.append("price", this.pMinPrice);

                                                axios.put(
                                                    `/api/market/info/packing/price/${this.logged_info.mart_srl}`,
                                                    fddddd
                                                )
                                                    .then(result => {
                                                      console.log(
                                                          result.data.status +
                                                          "  - packing min price"
                                                      );
                                                      if (
                                                          result.data.status === "success"
                                                      ) {
                                                        this.getMarketInfo();
                                                      }
                                                    })
                                                    .catch(error => {
                                                      console.log(
                                                          `modify packing min price Infomation ${error}`
                                                      );
                                                    });
                                              }
                                            })
                                            .catch(error => {
                                              console.log(
                                                  `modify delivery min price Infomation ${error}`
                                              );
                                            });
                                      }
                                    })
                                    .catch(error => {
                                      console.log(
                                          `modify serviceTime Infomation ${error}`
                                      );
                                    });
                              }
                            })
                            .catch(error => {
                              console.log(`modify Packing Infomation ${error}`);
                            });
                      }
                      this.$bvModal.msgBoxOk(
                          "배송(포장)가능시간정보가 수정되었습니다.",
                          msgModalOptCenter
                      );
                    })
                    .catch(error => {
                      console.log(`modify DeliveryInfomation ${error}`);
                    });
              }
            });
      }
    },

    //정기휴무일 삭제
    deleteOffday(close_day_srl) {
      console.log(close_day_srl);

      let url = `/api/market/info/outtime/${this.logged_info.mart_srl}`

      axios.delete(url, {
        data: {
          close_day_srl: close_day_srl
        }
      })
          .then(response => {
            console.log(response.data);
            this.getMarketInfo();
          })
          .catch(error => {
            console.log(error);
          });
    },

    //정기휴무일등록
    addOffday() {
      console.log(this.selectedYoil);
      console.log(this.selectedWeek);

      const fd = new FormData();
      fd.append("week", this.selectedWeek);
      fd.append("yoil", this.selectedYoil);

      let url = `/api/market/info/outtime/${this.logged_info.mart_srl}`

      axios.post(url, fd)
          .then(response => {
            console.log(response.data);
            this.getMarketInfo();
          })
          .catch(error => {
            console.log(error);
          });
    },

    //공휴일 휴무설정
    setLegalHolidayOnOff() {
      var holiday = "";
      if (this.legalholiday) {
        holiday = "Y";
      } else {
        holiday = "N";
      }
      const fd = new FormData();
      fd.append("holiday", holiday);

      let url = `/api/market/info/holiday/${this.logged_info.mart_srl}`

      axios.put(url, fd)
          .then(response => {
            console.log(response.data);
            if (response.data.status === "success" && this.legalholiday) {
              this.$bvModal.msgBoxOk(
                  "공휴일 휴무가 설정되었습니다.",
                  msgModalOptCenter
              );
            } else {
              this.$bvModal.msgBoxOk(
                  "공휴일 휴무가 취소되었습니다.",
                  msgModalOptCenter
              );
            }
            this.getMarketInfo();
          })
          .catch(error => {
            console.log(error);
          });
    },

    //웹에서 주문 결제 설정
    setWebOrder() {
      const url = `/api/market/info/mrtbuybtn/${this.logged_info.mart_srl}`;

      let tmp = "";
      if (this.webOrderCheck) {
        tmp = "Y";
      } else {
        tmp = "N";
      }
      const param = {use: tmp};

      axios
          .put(url, param)
          .then(res => {
            console.log(res.data);

            if (res.data.status === "success" && this.webOrderCheck) {
              this.$bvModal.msgBoxOk(
                  "웹에서 주문 결제가 활성화 되었습니다.",
                  msgModalOptCenter
              );
            } else {
              this.$bvModal.msgBoxOk(
                  "웹에서 주문 결제가 비활성화 되었습니다.",
                  msgModalOptCenter
              );
            }
            this.getMarketInfo();
          })
          .catch(err => console.log("Mrt Buy Btn Error :", err));
    },

    //만나서 현금결제 설정
    setMNCash() {
      var seecash = "";
      if (this.meetNcash) {
        seecash = "Y";
      } else {
        seecash = "N";
      }
      const fd = new FormData();
      fd.append("seecash", seecash);

      let url = `/api/market/info/seecash/${this.logged_info.mart_srl}`

      axios.put(url, fd)
          .then(response => {
            console.log(response.data);
            if (response.data.status === "success" && this.meetNcash) {
              this.$bvModal.msgBoxOk(
                  "만나서 현금 결제가 활성화 되었습니다.",
                  msgModalOptCenter
              );
            } else {
              this.$bvModal.msgBoxOk(
                  "만나서 현금 결제가 비활성화 되었습니다.",
                  msgModalOptCenter
              );
            }
            this.getMarketInfo();
          })
          .catch(error => {
            console.log(error);
          });
    },

    //만나서 카드결제 설정
    setMNCard() {
      var seecard = "";
      if (this.meetNcard) {
        seecard = "Y";
      } else {
        seecard = "N";
      }
      const fd = new FormData();
      fd.append("seecard", seecard);

      let url = `/api/market/info/seecard/${this.logged_info.mart_srl}`

      axios.put(url, fd)
          .then(response => {
            console.log(response.data);
            if (response.data.status === "success" && this.meetNcard) {
              this.$bvModal.msgBoxOk(
                  "만나서 카드 결제가 활성화 되었습니다.",
                  msgModalOptCenter
              );
            } else {
              this.$bvModal.msgBoxOk(
                  "만나서 카드 결제가 비활성화 되었습니다.",
                  msgModalOptCenter
              );
            }
            this.getMarketInfo();
          })
          .catch(error => {
            console.log(error);
          });
    },

    //임시휴업일 설정
    setTmpholiday() {
      if (
          this.marketInfoSet.market_info.tmpclosingday.tmp_closing_day_srl == null
      ) {
        this.$bvModal
            .msgBoxConfirm("임시휴업일을 등록하시겠습니까?", msgConfirmOptCenter)
            .then(sel => {
              if (sel) {
                const fd = new FormData();
                fd.append("sday", this.tmpholidaySdate);
                fd.append("stime", this.tmpholidayStime);
                fd.append("eday", this.tmpholidayEdate);
                fd.append("etime", this.tmpholidayEtime);

                let url = `/api/market/info/tmp/outtime/${this.logged_info.mart_srl}`

                axios.post(url, fd)
                    .then(response => {
                      console.log(response.data);
                      if (response.data.status === "success") {
                        this.$bvModal.msgBoxOk(
                            "임시 휴무일이 설정되었습니다.",
                            msgModalOptCenter
                        );
                      }
                      this.getMarketInfo();
                    })
                    .catch(error => {
                      console.log(error);
                    });
              }
            });
      } else {
        this.$bvModal
            .msgBoxConfirm("임시휴업일을 수정하시겠습니까?", msgConfirmOptCenter)
            .then(sel => {
              if (sel) {
                const fd = new FormData();
                fd.append("sday", this.tmpholidaySdate);
                fd.append("stime", this.tmpholidayStime);
                fd.append("eday", this.tmpholidayEdate);
                fd.append("etime", this.tmpholidayEtime);
                fd.append("tmp_closing_day_srl", this.tmpClosingDaySrl);

                let url = `/api/market/info/tmp/outtime/${this.logged_info.mart_srl}`

                axios.put(url, fd)
                    .then(response => {
                      console.log(response.data);
                      if (response.data.status === "success") {
                        this.$bvModal.msgBoxOk(
                            "임시 휴무일이 수정되었습니다.",
                            msgModalOptCenter
                        );
                      }
                      this.getMarketInfo();
                    })
                    .catch(error => {
                      console.log(error);
                    });
              }
            });
      }
    },

    // 임시휴업일 삭제
    delTmpHoliday() {
      if (
          this.marketInfoSet.market_info.tmpclosingday.tmp_closing_day_srl != null
      ) {
        this.$bvModal
            .msgBoxConfirm(
                "임시휴무일 정보를 삭제하시겠습니까?",
                msgConfirmOptCenter
            )
            .then(sel => {
              if (sel) {
                let url = `/api/market/info/tmp/outtime/${this.logged_info.mart_srl}`

                axios.delete(url, {
                  data: {
                    tmp_closing_day_srl: this.tmpClosingDaySrl
                  }
                })
                    .then(response => {
                      console.log(response.data);
                      if (response.data.status === "success") {
                        this.$bvModal.msgBoxOk(
                            "임시 휴무일 정보가 삭제되었습니다.",
                            msgModalOptCenter
                        );
                      }
                      this.getMarketInfo();
                    })
                    .catch(error => {
                      console.log(error);
                    });
              }
            });
      }
    },


  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :btn-name="btnName"/>
    <div class="row">
      <div class="col-xxl-3 col-lg-4">
        <!-- 기본정보 영역 -->
        <div class="card">
          <div class="card-header">
            <h5 style="margin: 0">기본정보</h5>
          </div>
          <div class="card-body">
            <!--            <hr class="my-4" />-->
            <p class="text-muted mb-1">아이디 (사업자등록번호) :</p>
            <h5 class="font-size-18 text-truncate">
              {{ this.logged_info.biznum }}
            </h5>

            <p class="text-muted mb-1">대표자명 :</p>
            <h5 class="font-size-18 text-truncate">
              {{ this.logged_info.ceoname }}
            </h5>

            <p class="text-muted mb-1">마켓명 :</p>
            <h5 class="font-size-18 text-truncate">
              {{ this.logged_info.mart_name }}
            </h5>
          </div>
        </div>
        <!-- end card -->
        <!-- 기본정보 영역 End-->
      </div>
      <!-- end col -->

      <div class="col-xxl-9 col-lg-8">
        <!-- 추가정보(전화번호변경 영역) -->
        <div class="card">
          <div class="card-header">
            <h5 style="margin: 0">추가정보</h5>
          </div>
          <div class="card-body">
            <form @submit.prevent>
              <!--              <div class="card border shadow-none mb-4">-->
              <!--                <div class="card-body">-->
              <div class="mb-2">
                <div class="row">
                  <div class="mb-3">
                    <label class="form-label" for="ceo_phone"
                    >대표자 휴대전화번호</label
                    >
                    <input
                        type="text"
                        class="form-control"
                        id="ceo_phone"
                        placeholder="대표자 휴대전화번호"
                        onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                        v-model="ceoPhone"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="market_phone"
                    >마켓 전화번호</label
                    >
                    <input
                        type="text"
                        class="form-control"
                        id="market_phone"
                        placeholder="마켓 전화번호"
                        onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                        v-model="marketTel"
                    />
                  </div>
                </div>
              </div>
              <!--                </div>-->
              <!--              </div>-->
              <!-- end card -->

              <div class="text-end">
                <button class="btn btn-primary w-sm" @click="modifyPhoneInfo">
                  추가정보 적용하기
                </button>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
        <!-- 추가정보(전화번호변경 영역) End -->

        <!-- 영업시간변경 영역 -->
        <div class="card" v-if="logged_info.business_type !== 'clan'">
          <div class="card-header">
            <h5 style="margin: 0">영업시간정보설정</h5>
          </div>
          <div class="card-body">
            <form @submit.prevent>
              <div class="mb-2">
                <div>
                  <!-- 24시간 운영 여부 -->
                  <div class="form-check form-switch form-switch-md mb-2">
                    <input
                        v-model="allday"
                        class="form-check-input"
                        type="checkbox"
                        id="alldayButton"
                    />
                    <label class="form-check-label" for="alldayButton">
                      24시간 운영 마트
                    </label>
                  </div>
                  <div
                      class="form-check form-switch form-switch-md mb-2"
                      v-show="!allday"
                  >
                    <input
                        v-model="m_weekdaysSame"
                        class="form-check-input"
                        type="checkbox"
                        id="weekdaysSame"
                    />
                    <label class="form-check-label" for="weekdaysSame">
                      7일 모두 동일
                    </label>
                  </div>
                  <div v-show="!allday">
                    <div class="row">
                      <div class="col-lg-1">
                        <label class="form-label col-lg-1"></label>
                      </div>
                      <div class="col-lg-5" style="padding-left: 30px">
                        <label class="form-label col-lg-6">영업시작시간</label>
                      </div>
                      <div class="col-lg-5" style="padding-left: 30px">
                        <label class="form-label col-lg-6">영업종료시간</label>
                      </div>
                    </div>
                    <div v-show="m_weekdaysSame">
                      <div class="row" style="margin-bottom: 10px">
                        <div class="col-lg-1" style="align-self: end; text-align: end; padding-right: 20px">
                          <label class="form-label col-lg-1">매일</label>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_everyday_stime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_everyday_etime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                      </div>
                    </div>
                    <div v-show="!m_weekdaysSame">
                      <div class="row" style="margin-bottom: 10px">
                        <div
                            class="col-lg-1"
                            style="align-self: end; text-align: end; padding-right: 20px"
                        >
                          <label class="form-label col-lg-1">월</label>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_mon_stime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_mon_etime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                      </div>
                      <div class="row" style="margin-bottom: 10px">
                        <div
                            class="col-lg-1"
                            style="align-self: end; text-align: end; padding-right: 20px"
                        >
                          <label class="form-label col-lg-1">화</label>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_tue_stime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_tue_etime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                      </div>
                      <div class="row" style="margin-bottom: 10px">
                        <div
                            class="col-lg-1"
                            style="align-self: end; text-align: end; padding-right: 20px"
                        >
                          <label class="form-label col-lg-1">수</label>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_wed_stime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_wed_etime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                      </div>
                      <div class="row" style="margin-bottom: 10px">
                        <div
                            class="col-lg-1"
                            style="align-self: end; text-align: end; padding-right: 20px"
                        >
                          <label class="form-label col-lg-1">목</label>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_thu_stime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_thu_etime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                      </div>
                      <div class="row" style="margin-bottom: 10px">
                        <div
                            class="col-lg-1"
                            style="align-self: end; text-align: end; padding-right: 20px"
                        >
                          <label class="form-label col-lg-1">금</label>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_fri_stime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_fri_etime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                      </div>
                      <div class="row" style="margin-bottom: 10px">
                        <div
                            class="col-lg-1"
                            style="align-self: end; text-align: end; padding-right: 20px"
                        >
                          <label class="form-label col-lg-1">토</label>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_sat_stime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_sat_etime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                      </div>
                      <div class="row" style="margin-bottom: 10px">
                        <div
                            class="col-lg-1"
                            style="align-self: end; text-align: end; padding-right: 20px"
                        >
                          <label class="form-label col-lg-1">일</label>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_sun_stime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                        <div class="col-lg-5">
                          <flat-pickr
                              v-model="m_sun_etime"
                              :config="timeOptions"
                              class="form-control"
                          >
                          </flat-pickr>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--                </div>-->
              <!--              </div>-->
              <!-- end card -->

              <div class="text-end">
                <button class="btn btn-primary w-sm" @click="modifyMarketOC">
                  변경사항저장
                </button>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
        <!-- 영업시간변경 영역 End -->

        <ControlMsg v-if="logged_info.business_type !== 'clan'" ref="ctrMsg" :mrtSms="makeObject()"></ControlMsg> <!--:mrtSms="makeObject()"-->

        <!-- 웹에서 주문 결제 설정 -->
        <div class="card" v-if="logged_info.business_type !== 'clan'">
          <div class="card-header">
            <h5 style="margin: 0">웹(광고링크)에서 주문/결제 기능 설정</h5>
          </div>
          <div class="card-body">
            <form @submit.prevent>
              <!--              <div class="card border shadow-none mb-4">-->
              <!--                <div class="card-body">-->
              <div class="mb-2">
                <div class="form-check form-switch form-switch-md">
                  <input
                      v-model="webOrderCheck"
                      class="form-check-input"
                      type="checkbox"
                      @change="setWebOrder"
                  />
                  <label
                      class="form-check-label"
                      style="padding-right: 50px">
                    웹에서 주문/결제 가능
                  </label>
                </div>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- 웹에서 주문 결제 설정 End -->

        <!-- 배송가능시간변경 영역 -->
        <div class="card" v-if="logged_info.business_type !== 'clan'">
          <div class="card-header">
            <h5 style="margin: 0">배송(포장)가능시간정보설정</h5>
          </div>
          <div class="card-body">
            <form @submit.prevent>
              <!--              <div class="card border shadow-none mb-4">-->
              <!--                <div class="card-body">-->
              <div class="mb-2">
                <div>
                  <div class="p-0">
                    <div style="display: flex; align-items: center; margin-bottom: 8px;">
                      <div class="form-check form-switch form-switch-md">
                        <input
                            v-model="delivery"
                            class="form-check-input"
                            type="checkbox"
                            id="deliveryAble"/>
                        <label
                            class="form-check-label"
                            for="deliveryAble"
                            style="padding-right: 50px">
                          배송가능
                        </label>
                      </div>
                      <div style="margin-top: 2px">
                        <label
                            class="form-label "
                            for="pack"
                            v-show="delivery"
                            style="margin: 0 6px 0 0;">
                          배송최소구매조건
                        </label>
                        <input
                            type="number"
                            class="form-control"
                            id="pack"
                            placeholder="30000"
                            v-model="dMinPrice"
                            style="width: 100px; display: inline-block; margin-right: 4px; text-align: right"
                            v-show="delivery"/>
                        <label class="form-label m-0" for="pack" v-show="delivery">
                          원
                        </label>
                      </div>
                    </div>
                    <div style="display: flex; align-items: center; margin-bottom: 10px;">
                      <div class="form-check form-switch form-switch-md">
                        <input
                            v-model="packing"
                            class="form-check-input"
                            type="checkbox"
                            id="PickupAble"/>
                        <label
                            class="form-check-label"
                            for="PickupAble"
                            style="padding-right: 50px">
                          포장가능
                        </label>
                      </div>
                      <div style="margin-top: 2px;">
                        <label
                            class="form-label"
                            for="deli"
                            v-show="packing"
                            style="margin: 0 6px 0 0;">
                          포장최소구매조건
                        </label>
                        <input
                            type="number"
                            class="form-control"
                            id="deli"
                            placeholder="30000"
                            v-model="pMinPrice"
                            style="width: 100px; display: inline-block; margin-right: 4px; text-align: right"
                            v-show="packing"
                        />
                        <label class="form-label m-0" for="deli" v-show="packing">
                          원
                        </label>
                      </div>
                    </div>
                    <div v-show="delivery || packing">
                      <div class="form-check form-switch form-switch-md" style="padding-top: 4px;">
                        <input
                            v-model="d_weekdaysSame"
                            class="form-check-input"
                            type="checkbox"
                            id="dtSame"
                        />
                        <label class="form-check-label" for="dtSame">
                          매일 동일
                        </label>
                      </div>

                      <div class="row">
                        <div class="col-lg-1">
                          <label class="form-label col-lg-1"></label>
                        </div>
                        <!--                        <div class="col-lg-5" style="padding-left: 30px">
                                                  <label class="form-label col-lg-6">배송가능시작시간</label>
                                                </div>
                                                <div class="col-lg-5" style="padding-left: 30px">
                                                  <label class="form-label col-lg-6">배송가능종료시간</label>
                                                </div>-->
                      </div>
                      <div v-show="d_weekdaysSame">
                        <div class="row" style="margin-bottom: 10px">
                          <div class="col-lg-1" style="align-self: end; text-align: end; padding-right: 20px">
                            <label class="form-label">매일</label>
                          </div>
                          <div class="col-lg-5">
                            <label class="form-label col-lg-6">배송가능시작시간</label>
                            <flat-pickr
                                v-model="d_everyday_stime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                          <div class="col-lg-5">
                            <label class="form-label col-lg-6">배송가능종료시간</label>
                            <flat-pickr
                                v-model="d_everyday_etime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                        </div>
                      </div>
                      <div v-show="!d_weekdaysSame">
                        <div class="row" style="margin-bottom: 10px">
                          <div
                              class="col-lg-1"
                              style="align-self: end; text-align: end; padding-right: 20px"
                          >
                            <label class="form-label col-lg-1">월</label>
                          </div>
                          <div class="col-lg-5">
                            <label class="form-label col-lg-6">배송가능시작시간</label>
                            <flat-pickr
                                v-model="d_mon_stime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                          <div class="col-lg-5">
                            <label class="form-label col-lg-6">배송가능종료시간</label>
                            <flat-pickr
                                v-model="d_mon_etime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                        </div>
                        <div class="row" style="margin-bottom: 10px">
                          <div
                              class="col-lg-1"
                              style="align-self: end; text-align: end; padding-right: 20px"
                          >
                            <label class="form-label col-lg-1">화</label>
                          </div>
                          <div class="col-lg-5">
                            <flat-pickr
                                v-model="d_tue_stime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                          <div class="col-lg-5">
                            <flat-pickr
                                v-model="d_tue_etime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                        </div>
                        <div class="row" style="margin-bottom: 10px">
                          <div
                              class="col-lg-1"
                              style="align-self: end; text-align: end; padding-right: 20px"
                          >
                            <label class="form-label col-lg-1">수</label>
                          </div>
                          <div class="col-lg-5">
                            <flat-pickr
                                v-model="d_wed_stime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                          <div class="col-lg-5">
                            <flat-pickr
                                v-model="d_wed_etime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                        </div>
                        <div class="row" style="margin-bottom: 10px">
                          <div
                              class="col-lg-1"
                              style="align-self: end; text-align: end; padding-right: 20px"
                          >
                            <label class="form-label col-lg-1">목</label>
                          </div>
                          <div class="col-lg-5">
                            <flat-pickr
                                v-model="d_thu_stime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                          <div class="col-lg-5">
                            <flat-pickr
                                v-model="d_thu_etime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                        </div>
                        <div class="row" style="margin-bottom: 10px">
                          <div
                              class="col-lg-1"
                              style="align-self: end; text-align: end; padding-right: 20px"
                          >
                            <label class="form-label col-lg-1">금</label>
                          </div>
                          <div class="col-lg-5">
                            <flat-pickr
                                v-model="d_fri_stime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                          <div class="col-lg-5">
                            <flat-pickr
                                v-model="d_fri_etime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                        </div>
                        <div class="row" style="margin-bottom: 10px">
                          <div
                              class="col-lg-1"
                              style="align-self: end; text-align: end; padding-right: 20px"
                          >
                            <label class="form-label col-lg-1">토</label>
                          </div>
                          <div class="col-lg-5">
                            <flat-pickr
                                v-model="d_sat_stime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                          <div class="col-lg-5">
                            <flat-pickr
                                v-model="d_sat_etime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                        </div>
                        <div class="row" style="margin-bottom: 10px">
                          <div
                              class="col-lg-1"
                              style="align-self: end; text-align: end; padding-right: 20px"
                          >
                            <label class="form-label col-lg-1">일</label>
                          </div>
                          <div class="col-lg-5">
                            <flat-pickr
                                v-model="d_sun_stime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                          <div class="col-lg-5">
                            <flat-pickr
                                v-model="d_sun_etime"
                                :config="timeOptions"
                                class="form-control"
                            >
                            </flat-pickr>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--                </div>-->
              <!--              </div>-->
              <!-- end card -->

              <div class="text-end">
                <button class="btn btn-primary w-sm" @click="serviceTimeModify">
                  변경사항저장
                </button>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- 배송가능시간변경 영역 End -->
        <!-- end card -->

        <!-- 만나서 결제 방식 설정 -->
        <div class="card" v-if="logged_info.business_type !== 'clan'">
          <div class="card-header">
            <h5 style="margin: 0">만나서 결제 방식 설정</h5>
          </div>
          <div class="card-body">
            <form @submit.prevent>
              <!--              <div class="card border shadow-none mb-4">-->
              <!--                <div class="card-body">-->

              <div style="display: flex" class="p-0">
                <div class="form-check form-switch form-switch-md">
                  <input
                      v-model="meetNcash"
                      class="form-check-input"
                      type="checkbox"
                      @change="setMNCash"/>
                  <label
                      class="form-check-label"
                      style="padding-right: 50px; word-break: keep-all">
                    만나서 현금결제
                  </label>
                </div>
                <div class="form-check form-switch form-switch-md">
                  <input
                      v-model="meetNcard"
                      class="form-check-input"
                      type="checkbox"
                      @change="setMNCard"
                  />
                  <label
                      class="form-check-label"
                      style="padding-right: 50px; word-break: keep-all">
                    만나서 카드결제
                  </label>
                </div>
              </div>

            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- 만나서 결제 방식 설정 End -->

        <!-- 휴무일설정 영역 -->
        <div class="card" v-if="logged_info.business_type !== 'clan'">
          <div class="card-header">
            <h5 class="m-0">휴무일 설정</h5>
          </div>
          <div class="card-body">
            <form @submit.prevent>
              <!--              <div class="card border shadow-none mb-4">-->
              <!--                <div class="card-body">-->
              <div class="form-check form-switch form-switch-md">
                <input
                    v-model="legalholiday"
                    class="form-check-input"
                    type="checkbox"
                    @change="setLegalHolidayOnOff"
                />
                <label class="form-check-label" style="padding-right: 50px">
                  공휴일휴무
                </label>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- 휴무일설정 영역 End -->

        <div class="card">
          <div class="card-header">
            <h5 class="m-0">정기휴무일</h5>
          </div>
          <div class="card-body">
            <div style="display: flex">
              <select
                  id="closingdayweek"
                  class="form-control select2 select2-hidden-accessible"
                  style="width: 150px; margin-right: 20px;"
                  v-model="selectedWeek"
                  data-select2-id="1"
                  tabindex="-1"
                  aria-hidden="true"
              >
                <option
                    v-for="(item, index) in week"
                    :key="index"
                    :value="item.name"
                >{{ item.name }}
                </option
                >
              </select>
              <select
                  id="closingdayyoil"
                  class="form-control select2 select2-hidden-accessible"
                  style="width: 150px;"
                  data-select2-id="2"
                  tabindex="-1"
                  aria-hidden="true"
                  v-model="selectedYoil"
              >
                <option
                    v-for="(item, index) in yoil"
                    :key="index"
                    :value="item.name"
                >{{ item.name }}
                </option
                >
              </select>
              <b-button
                  class="btn btn-soft-primary"
                  style="width: 80px; margin-left: 50px; "
                  @click="addOffday"
              >추가
              </b-button>
            </div>
            <div v-show="offdays.length > 0">
              <hr/>

              <label
                  class="form-check-label"
                  style="padding-right: 50px; font-size: 1.1em; margin-bottom: 20px">
                등록된 정기 휴무일
              </label>
              <div class="row-12" style="margin-left: 50px">
                <tr
                    v-for="(item, index) in this.offdays"
                    :key="index"
                    style="vertical-align: middle; padding-right: 50px;">
                  <td scope="row" style="text-align: right; ">
                    {{ item.week }}
                  </td>
                  <td style="text-align: center; padding-left: 20px">
                    {{ " " + item.yoil }}
                  </td>
                  <b-button
                      class="btn btn-soft-danger"
                      style=" width: 80px; vertical-align: middle; margin-left: 100px; margin-bottom: 6px"
                      @click="deleteOffday(item.close_day_srl)">
                    삭제
                  </b-button>
                </tr>
              </div>
            </div>
          </div>
        </div>

        <!-- 임시휴무일설정 영역 -->
        <div class="card" v-if="logged_info.business_type !== 'clan'">
          <div class="card-header">
            <h5 style="margin: 0">임시휴무일 설정</h5>
          </div>
          <div class="card-body">
            <form @submit.prevent>
              <!--              <div class="card border shadow-none mb-4">-->
              <!--                <div class="card-body">-->
              <div>
                <div class="form-check form-switch form-switch-md">
                  <input
                      v-model="tmpholiday"
                      class="form-check-input"
                      type="checkbox"
                      @change="delTmpHoliday"
                  />
                  <label
                      class="form-check-label"
                      style="padding-right: 50px"
                  >임시휴무일 지정</label
                  >
                </div>
                <div
                    v-if="tmpholiday"
                    style="margin-top: 20px; margin-left: 50px"
                >
                  <label
                      class="form-check-label"
                      style="padding-right: 50px; font-size: 1.1em"
                  >임시휴무일 정보</label
                  >
                  <div
                      class="row"
                      style="margin-top: 20px; margin-bottom: 20px; margin-left: 50px;"
                  >
                    <div class="col-md-2">
                      <flat-pickr
                          class="form-control"
                          v-model="tmpholidaySdate"
                          :config="config"
                      >
                      </flat-pickr>
                    </div>
                    <div class="col-md-2">
                      <flat-pickr
                          v-model="tmpholidayStime"
                          :config="timeOptions"
                          class="form-control"
                      >
                      </flat-pickr>
                    </div>
                    <p class="col-md-1" style="vertical-align: middle">
                      부터
                    </p>
                  </div>
                  <div
                      class="row"
                      style="margin-top: 20px; margin-bottom: 40px; margin-left: 50px;"
                  >
                    <div class="col-md-2">
                      <flat-pickr
                          class="form-control"
                          v-model="tmpholidayEdate"
                          :config="config"
                      >
                      </flat-pickr>
                    </div>
                    <div class="col-md-2">
                      <flat-pickr
                          v-model="tmpholidayEtime"
                          :config="timeOptions"
                          class="form-control"
                      >
                      </flat-pickr>
                    </div>
                    <p
                        class="col-md-1"
                        style="height:100%; align-items: center"
                    >
                      까지
                    </p>
                  </div>
                  <div class="text-end">
                    <button
                        class="btn btn-primary w-sm"
                        @click="setTmpholiday"
                    >
                      임시휴무일 설정하기
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- 임시휴무일설정 영역 End -->

        <div class="card">
          <div class="card-header">
            <h5 style="margin: 0">비밀번호 변경</h5>
          </div>
          <div class="card-body">
            <form @submit.prevent>
              <!--              <div class="card border shadow-none mb-4">-->
              <!--                <div class="card-body">-->
              <div class="mb-4">
                <div class="row">
                  <div class="mb-3">
                    <label class="form-label" for="original_pass"
                    >기존 비밀번호</label
                    >
                    <input
                        type="password"
                        class="form-control"
                        id="original_pass"
                        placeholder="기존 비밀번호"
                        v-model="oldPw"
                    />
                  </div>
                </div>
                <div>
                  <div class="mb-3">
                    <label class="form-label" for="new_pass"
                    >새로운 비밀번호</label
                    >
                    <input
                        type="password"
                        class="form-control"
                        id="new_pass"
                        placeholder="새로운 비밀번호"
                        v-model="newPw"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="new_pass_confirm"
                    >새로운 비밀번호 확인</label
                    >
                    <input
                        type="password"
                        class="form-control"
                        id="new_pass_confirm"
                        placeholder="새로운 비밀번호 확인"
                        v-model="newPwChk"
                    />
                  </div>
                </div>
              </div>
              <!--                </div>-->
              <!--              </div>-->
              <!-- end card -->

              <div class="text-end">
                <button class="btn btn-primary w-sm" @click="modifyPassword">
                  비밀번호 변경하기
                </button>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>
